/**
 * pre列表
 */
import Vue from 'vue'
import { Message,Page,Input,DatePicker,locale,Table,Button,Icon,Dropdown,DropdownMenu,DropdownItem } from 'iview';
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
import { formatUnit } from '@/utils/tools'
import { preTab,
    preColumns,
    getPreListService,
    delPreListService,
    getPreEditStatusService
} from '@/service/pre-service'
import { classification } from "@/service/spu-group-service/index";
import Header from '@/components/Header/header.vue'
import { mapMutations } from "vuex";
const PreList = {
    name:'PreList',
    components:{
        Header
    },
    data() {
        return {
            formatUnit:formatUnit,
            monthValue:'', //年月值
            weekValue:'', //周值
            preTab:preTab,//tab 数据集
            preColumns:preColumns,//thead 数据集
            preLoading:false,
            preStatus:false,
            classification:classification, //分类
            preArray:[], //搜索数据集
            selectAllCancel:[], //全选时的临时存储
            selectAllContentCancel:[], //全选时的临时存储
            btnStatus:false,
            preParams:{  //选中参数
                cid1:1,
                preValue:1,
                checkContentInput:[],
            },
        }
    },
    created() {
        this.SET_TYPE_ID('1')
        this.SET_LAUNCH_TAB('1')
        this.onPreList().then()
    },
    methods: {
        ...mapMutations('launch',['SET_LAUNCH_ID','SET_LAUNCH_NAME','SET_LAUNCH_TITLE','SET_TYPE_ID','SET_LAUNCH_TAB']),
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAll(selection) {
            this.selectAllContentCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.preParams.checkContentInput){
                    if(selection[key].id === this.preParams.checkContentInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.preParams.checkContentInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAllCancel() {
            let selection = this.selectAllContentCancel
            for(let key in selection){
                for(let index in this.preParams.checkContentInput){
                    if(selection[key].id === this.preParams.checkContentInput[index]){
                        this.preParams.checkContentInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelectContent(selection,row) {
            let mediaList = this.preParams.checkContentInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.preParams.checkContentInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectContentCancel(selection,row) {
            for(let key in this.preParams.checkContentInput){
                if(this.preParams.checkContentInput[key] === row.id){
                    this.preParams.checkContentInput.splice(key,1);
                }
            }
        },
        /**
         * 删除数据
         */
        onContentDelete(){
            if(this.preParams.checkContentInput.length === 0){
                Message.error('Please select')
            }else{
                this.preStatus = true;
            }
        },
        /**
         * 删除数据确认
         */
        onContentDeleteConfirm() {
            if(this.preParams.checkContentInput.length === 0){
                Message.error('Please select');
                return;
            }
            this.onDelPreList().then()
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 修改状态
         * @param value
         * @param id
         */
        onSelectItem(value,id) {
            this.onPreEditStatus(value,id).then()
        },
        /**
         * 添加数据
         */
        onAddContentList(){
            this.SET_LAUNCH_ID('')
            if(this.preParams.preValue === 1){
                this.SET_LAUNCH_NAME('Image')
            }
            if(this.preParams.preValue === 2){
                this.SET_LAUNCH_NAME('Brands')
            }
            if(this.preParams.preValue === 3){
                this.SET_LAUNCH_NAME('Categories')
            }
            this.$router.push('/pre/edit')
        },
        /**
         * 跳转编辑
         * @param id
         */
        onEditContent(id) {
            this.SET_LAUNCH_ID(id)
            if(this.preParams.preValue === 1){
                this.SET_LAUNCH_NAME('Image')
            }
            if(this.preParams.preValue === 2){
                this.SET_LAUNCH_NAME('Brands')
            }
            if(this.preParams.preValue === 3){
                this.SET_LAUNCH_NAME('Categories')
            }
            this.$router.push('/pre/edit')
        },
        /**
         * 选择分类id
         * @param id
         */
        onTypeSelect(id){
            this.preParams.cid1 = id
            this.SET_TYPE_ID(id)
            this.onPreList().then()
        },
        /**
         * 选择tab
         * @param value
         */
        onSelectTab(value) {
            this.preParams.preValue = value
            this.SET_LAUNCH_TAB(value)
            this.preParams.checkContentInput = []
            this.onPreList().then()
        },
        /**
         * 获取Pre数据
         * @returns {Promise<void>}
         */
        async onPreList() {
            try {
                this.preLoading = true
                let params = {
                    cid1:this.preParams.cid1,
                    type:this.preParams.preValue,
                }
                const {code,data} = await getPreListService(params)
                if(code === 1){
                    this.preArray = data
                }
                this.preLoading = false
            }catch (error){
                this.preLoading = false
                console.log(error)
            }
        },
        /**
         * 修改列表状态
         * @returns {Promise<void>}
         */
        async onPreEditStatus(status,id) {
            try {
                let params = {
                    id:id,
                    status:status,
                    type:this.preParams.preValue
                }
                const { code } = await getPreEditStatusService(params)
                if(code === 1){
                    this.onPreList().then()
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除pre数据
         * @returns {Promise<void>}
         */
        async onDelPreList() {
            try {
                if(this.preParams.checkContentInput.length === 0){
                    Message.error('Please select');
                    return;
                }
                this.btnStatus = true
                let params = {
                    'ids':this.preParams.checkContentInput,
                    type:this.preParams.preValue
                }
                const { code } = await delPreListService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onPreList().then()
                    this.preParams.checkContentInput = []
                    this.preStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
    },
}
export default PreList
